const routes = {
  path: "/",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Home",
      component: () => import("./views/Resumen.vue"),
    },
  ],
  meta: { requiresAuth: true }
};

export default routes;
