const routes = {
  path: "/estudios",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Estudios",
      component: () => import("./views/Estudios.vue"),
    },
    {
      path: "/borradores",
      name: "Borradores",
      component: () => import("./views/Borradores.vue"),
      meta: {
        acceso: 'ENVIAR_ESTUDIOS'
      }
    },
    {
      path: "/papelera",
      name: "Papelera",
      props: () => ({ eliminados : true }),
      component: () => import("./views/Estudios.vue"),
    },
    {
      path: "express",
      name: "EnviarEstudioExpress",
      component: () => import("./views/EnviarEstudioExpress.vue"),
    },
    {
      path: "nuevo",
      name: "EnviarEstudio",
      component: () => import("./views/EnviarEstudio.vue"),
    },
    {
      path: "/detalles_estudio",
      name: "DetallesEstudio",
      component: () => import("./views/DetallesEstudio.vue"),
    },
    {
      path: "/instalaciones",
      name: "Instalaciones",
      component: () => import("./views/Instalaciones.vue"),
      acceso: 'EDITAR_ESTUDIOS'
    },
    {
      path: "/ventanas",
      name: "Ventanas",
      component: () => import("./views/Ventanas.vue"),
      acceso: 'EDITAR_ESTUDIOS'
    },
    {
      path: "/control-pagos",
      name: "ControlPagos",
      component: () => import("./views/ControlPagos.vue"),
      acceso: 'EDITAR_ESTUDIOS'
    },
    {
      path: "/tramites-instalaciones",
      name: "TramitesInstalaciones",
      component: () => import("./views/TramitesInstalaciones.vue"),
      acceso: 'EDITAR_ESTUDIOS'
    },
  ],
  meta: { requiresAuth: true }
};

export default routes;
