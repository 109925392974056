const routes = {
  path: "/colaboradores",
  component: () => import("./Module.vue"),
  meta: { requiresAuth: true, requiresAdmin: true, acceso: "VER_USUARIOS" },
  children: [
    {
      path: "",
      name: "Colaboradores",
      component: () => import("./views/Colaboradores.vue"),
    },
    {
      path: "nuevo",
      name: "NuevoColaborador",
      component: () => import("./views/NuevoColaborador.vue"),
      meta: { requiresAuth: true, requiresAdmin: true, acceso: "CONTROL_USUARIOS" },
    },
    {
      path: ":idUsuario",
      name: "Colaborador",
      props: (route) => Object.assign({}, route.query, route.params),
      component: () => import("./views/NuevoColaborador.vue"),
      meta: { requiresAuth: true, requiresAdmin: true, acceso: "CONTROL_USUARIOS" },
    },
  ],
};

export default routes;
